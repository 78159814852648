<template>
  <div>
    <div class="info">
      <p>访问记录</p>
    </div>
    <div class="choice">
      <!-- 消息提示有几个 -->
      <el-badge :value="a" class="item">
        <p class="active" ref="lie1" @click="lie(1)">谁看过我</p>
      </el-badge>
      <el-badge :value="b" class="item">
        <p ref="lie2" @click="lie(2)">我看过谁</p>
      </el-badge>
    </div>

    <!-- 访问框 -->
    <div class="follow">
      <!-- 谁看过我 -->
      <div class="messages-li" v-show="lieB">
        <ul class="messages-li" v-show="!kong">
          <li v-for="(item, index) in kanwo" :key="index">
            <!-- 头像 -->
            <div class="touxi">
              <img :src="item.Img" alt="" @click="ziliao(item.objId)" />
            </div>
            <!-- 资料 -->
            <div class="mess-box">
              <div class="mess-name">
                <!-- <p>{{ item.browseNickName }}<i>图标</i></p> -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    vertical-align: middle;
                  "
                >
                  <p style="margin-right: 10px; font-weight: bold">
                    {{ item.browseNickName }}
                  </p>
                  <img
                    :src="item.browseIsVipMember == 0 ? `` : `${MemberIcon}`"
                    alt=""
                    style="margin-right: 5px"
                  />
                  <img
                    :src="
                      item.browseIsVipMember == 2 ? `${realAuthStatusIcon}` : ``
                    "
                    alt=""
                  />
                </div>
              </div>
              <p class="qianmin" style="">
                <!-- {{ item.age }}岁｜{{ item.browseWorkAddrCityName }} -->
                <span>{{ item.browseAge + "岁" }}</span>
                <span v-if="item.browseWorkAddrCityName"
                  >&nbsp;|&nbsp;{{ item.browseWorkAddrCityName }}</span
                >
                <span v-if="item.browseOccBigCategory"
                  >&nbsp;|&nbsp;{{ item.browseOccBigCategory }}</span
                >
                <span v-if="item.browseHeight"
                  >&nbsp;|&nbsp;{{ item.browseHeight + "cm" }}</span
                >
                <span v-if="item.browseEducation"
                  >&nbsp;|&nbsp;{{ item.browseEducation }}</span
                >
                <span v-if="item.browseBuyCarStatus"
                  >&nbsp;|&nbsp;{{ item.browseBuyCarStatus }}</span
                >
                <span v-if="item.browseHouseStatus"
                  >&nbsp;|&nbsp;{{ item.browseHouseStatus }}</span
                >
              </p>
              <p class="cinum">
                第<span style="color: #fd686e">{{ item.browseCount }}</span
                >次查看你的资料
              </p>
              <p
                class="time"
                v-if="
                  new Date(item.NewTime).getDate() == new Date().getDate() &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                {{ $moment(item.NewTime).format("H:mm ") }}
              </p>
              <p
                class="time"
                v-else-if="
                  new Date(item.NewTime).getDate() ==
                    new Date().getDate() - 1 &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                <!-- {{ $moment(item.NewTime).subtract(1, "days").calendar() }} -->
                {{ $moment(item.NewTime).calendar() }}
              </p>
              <p class="time" v-else>
                {{ $moment(item.NewTime).format("MMM Do") }}
              </p>
            </div>
            <div class="Btn-box">
              <!-- 打招呼 -->
              <div>
                <div class="Btn" v-if="item.show" @click="send(item)">
                  <!-- src="../../../../assets/images/message/yezai_mess.png" -->
                  <img
                    class="z"
                    src="../../../../assets/images/info/mess-aL.png"
                    alt=""
                  />
                  <!-- ../../../../assets/images/info/mess-j.png -->
                  <img
                    class="b"
                    src="../../../../assets/images/info/mess-aaLL.png"
                    alt=""
                  />
                  打招呼
                </div>
                <div class="Btnactive" v-if="!item.show">已打招呼</div>
              </div>
              <!-- 关注 -->
              <div class="Btn" @click="follow(item)">
                <img
                  src="../../../../assets/images/message/yezai_aixin.png"
                  alt=""
                  class="z"
                />
                <img
                  v-if="item.followStatus == 1"
                  src="../../../../assets/images/info/mess-aixin.png"
                  alt=""
                  class="b"
                />
                <div class="z">
                  {{ item.followStatus == 1 ? "关注" : "已关注" }}
                </div>
                <div v-if="item.followStatus == 1" class="b">关注</div>
                <div v-if="item.followStatus == 0" class="b">取消关注</div>
              </div>
              <!-- 发消息 -->
              <div class="Btn" @click="chat(item.objId)">
                <img
                  class="z"
                  src="../../../../assets/images/message/yezai_message_mail.png"
                  alt=""
                />
                <img
                  class="b"
                  src="../../../../assets/images/info/mess-e.png"
                  alt=""
                />
                发消息
              </div>
            </div>
            <el-button type="text" @click="open(item)"
              ><i class="el-icon-close close"> </i
            ></el-button>
          </li>
          <!-- v-show="fen" -->
          <!-- <el-pagination
            
            @current-change="handleCurrentChangeL"
            :current-page="currentPage4"
            :hide-on-single-page="val"
            background
            layout="prev,pager,next"
            :total="total"
          ></el-pagination> -->

          <!-- 分页处理 -->
          <div class="low">
            <el-pagination
              @current-change="handleCurrentChangeL"
              :current-page="currentPage"
              :hide-on-single-page="fen"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </ul>
        <!-- xiao -->
        <div class="kong" v-show="kong">
          <img src="../../../../assets/images/message/fangwen.png" alt="" />
          <p style="line-height: 40px">暂时还没有人看过你</p>
          <div class="btn-box" @click="chuji">提高我的展示机会</div>
        </div>
      </div>

      <!-- 我看过的 --------------------------------------------->
      <div class="messages-li" v-show="lieA">
        <ul class="messages-li" v-show="!hong">
          <li v-for="(item, index) in kanguo" :key="index">
            <!-- 头像 -->
            <div class="touxi">
              <!-- @click="ziliao(item.browseMemberId)" -->
              <img :src="item.Img" alt="" @click="ziliao(item.objId)" />
            </div>
            <!-- 信息 -->
            <div class="mess-box">
              <div class="mess-name">
                <!-- <p>{{ item.browseNickName }} <i>图标</i></p> -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    vertical-align: middle;
                  "
                >
                  <p style="margin-right: 10px; font-weight: bold">
                    {{ item.browseNickName }}
                  </p>
                  <img
                    :src="item.browseIsVipMember == 0 ? `` : `${MemberIcon}`"
                    alt=""
                    style="margin-right: 5px"
                  />
                  <img
                    :src="
                      item.browseRealAuthStatus == 2
                        ? `${realAuthStatusIcon}`
                        : ``
                    "
                    alt=""
                  />
                </div>
              </div>
              <p class="qianmin" style="">
                <span>{{ item.browseAge + "岁" }}</span>
                <span v-if="item.browseWorkAddrCityName"
                  >&nbsp;|&nbsp;{{ item.browseWorkAddrCityName }}</span
                >
                <span v-if="item.browseOccBigCategory"
                  >&nbsp;|&nbsp;{{ item.browseOccBigCategory }}</span
                >
                <span v-if="item.browseHeight"
                  >&nbsp;|&nbsp;{{ item.browseHeight + "cm" }}</span
                >
                <span v-if="item.browseEducation"
                  >&nbsp;|&nbsp;{{ item.browseEducation }}</span
                >
                <span v-if="item.browseBuyCarStatus"
                  >&nbsp;|&nbsp;{{ item.browseBuyCarStatus }}</span
                >
                <span v-if="item.browseHouseStatus"
                  >&nbsp;|&nbsp;{{ item.browseHouseStatus }}</span
                >
              </p>
              <!-- 时间 -->
              <p
                class="time"
                v-if="
                  new Date(item.NewTime).getDate() == new Date().getDate() &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                {{ $moment(item.NewTime).format("H:mm ") }}
                <!-- {{ $moment(item.NewTime).calendar() }} -->
              </p>
              <p
                class="time"
                v-else-if="
                  new Date(item.NewTime).getDate() ==
                    new Date().getDate() - 1 &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                <!-- {{ $moment(item.NewTime).subtract(1, "days").calendar() }} -->
                {{ $moment(item.NewTime).calendar() }}
              </p>
              <p class="time" v-else>
                {{ $moment(item.NewTime).format("MMM Do") }}
              </p>
            </div>
            <div class="Btn-box">
              <!-- 打招呼 -->
              <div>
                <div class="Btn" v-if="item.show" @click="sendL(item)">
                  <img
                    class="z"
                    src="../../../../assets/images/info/mess-aL.png"
                    alt=""
                  />
                  <img
                    class="b"
                    src="../../../../assets/images/info/mess-aaLL.png"
                    alt=""
                  />
                  打招呼
                </div>
                <div class="Btnactive" v-if="!item.show">已打招呼</div>
              </div>
              <!-- 关注 -->
              <div class="Btn" @click="follow(item)">
                <img
                  src="../../../../assets/images/message/yezai_aixin.png"
                  alt=""
                  class="z"
                />
                <img
                  v-if="item.followStatus == 1"
                  src="../../../../assets/images/info/mess-aixin.png"
                  alt=""
                  class="b"
                />
                <div class="z">
                  {{ item.followStatus == 1 ? "关注" : "已关注" }}
                </div>
                <div v-if="item.followStatus == 1" class="b">关注</div>
                <div v-if="item.followStatus == 0" class="b">取消关注</div>
              </div>
              <!-- 发消息 -->
              <div class="Btn" @click="chat(item.objId)">
                <img
                  class="z"
                  src="../../../../assets/images/message/yezai_message_mail.png"
                  alt=""
                />
                <img
                  class="b"
                  src="../../../../assets/images/info/mess-e.png"
                  alt=""
                />
                发消息
              </div>
            </div>
            <el-button type="text" @click="open(item)"
              ><i class="el-icon-close close"> </i
            ></el-button>
          </li>
          <!-- 分页处理 -->
          <div class="low">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :hide-on-single-page="ye"
              background
              layout="prev, pager, next"
              :total="totalL"
            >
            </el-pagination>
          </div>
        </ul>
        <!-- 红娘来信 -->
        <div class="kong" v-show="hong">
          <img src="../../../../assets/images/message/fangwen.png" alt="" />
          <p style="text-align: center; line-height: 20px">
            你还没有看过别人呢 <br />
            看的越多，脱单的概率才越高
          </p>
          <div class="btn-box" @click="chuji">快去看看别人吧</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { browsingPage, followin, viewDel } from "@/api/login/login.js";
import { infocur } from "@/api/login/login.js"; //send
// import { sendmessage } from "@/api/member/member.js";
import { subTk } from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { loveLove } from "@/api/my/my.js";

export default {
  data() {
    return {
      a: "",
      b: "",
      value: false,
      hong: false,
      xiao: false,
      lieB: true,
      lieA: false,
      kong: false,
      // src: require("@/assets/images/index/avatar.png"),
      tableData: [],
      pageCurrent: 2,
      kanguo: [],
      kanwo: [],
      id: "",
      kid: "",
      followStatus: null,
      //数据回显
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ], //买车
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      // 分页
      pageSize: 10,
      total: null,
      totalL: 2,
      val: null,
      currentPage4: 1,
      currentPage: 1,
      follower: "",
      browseMemberId: "",
      beiguanzhu: "",
      zhuangtai: "",
      fen: true,
      ye: true,

      bsend: true,
      asend: false,
      tk: "",
      MemberIcon: require("@/assets/images/tubiao/yezai_member.png"),
      realAuthStatusIcon: require("@/assets/images/tubiao/yezai_auth.png"),
      idcard: "",
      sex: "",
    };
  },
  created() {
    this.csh();
    this.tkL();
  },
  methods: {
    // 环信登录
    login() {
      const _that = this;
      //-------------
      var options = {
        user: this.idcard,
        pwd: "yezai_user@easemob",
        appKey: _that.$WebIM.config.appkey,
        success: function (res) {
          console.log("登录成功", res);
        },
        error: function () {},
      };
      _that.$WebIM.conn.open(options);
    },

    // 打招呼01
    async send(item) {
      item.show = !item.show;
      this.sendMess(item.objId);
    },
    //打招呼02
    async sendL(item) {
      item.show = !item.show;
      this.sendMess(item.objId);
    },
    //打招呼
    sendMess(o) {
      let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      let msg = new this.$WebIM.message("txt", id); // 创建文本消息
      const _that = this;
      msg.set({
        msg: "你好", // 消息内容
        to: `${o}`, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
        chatType: "singleChat", // 设置为单聊
        success: function (id, serverMsgId) {
          console.log("send private text Success", id, serverMsgId);
          _that.$message({
            message: "打招呼发送成功",
            type: "success",
            offset: 100,
          });
          let d = {
            tid: o,
          };
          loveLove(d);
          _that.tkL();
        },
        fail: function (e) {
          _that.$message({
            message: "打招呼发送失败",
            type: "error",
            offset: 100,
          });
          console.log("Send private text error", e);
        },
      });
      // console.log(msg.body);
      // _that.WebIM.conn.send(msg.body);
      _that.$WebIM.conn.send(msg.body);
    },

    //临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    chuji() {
      let rel = this.$router.resolve({
        path: "/n/myyezai",
      });
      window.open(rel.href, "_blank");
    },
    //跳转资料页
    ziliao(item) {
      console.log("00", item);
      let a = {
        id: item,
      };
      let rel = this.$router.resolve({
        path: "/u",
        query: a,
      });
      window.open(rel.href, "_blank");
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getseenme();
      this.currentPage4 = val;
    },
    handleCurrentChangeL(val) {
      console.log(`当前页: ${val}`);
      this.getxinxi();
      this.currentPage = val;
    },
    // 谁看过我点击关注
    async followme(item) {
      if (item.browseFollowStatus == 1) {
        this.zhuangtai = 0;
      } else {
        this.zhuangtai = 1;
      }
      let Data = {
        followMemberId: item.objId,
        opFlag: this.zhuangtai,
        tk: this.tk,
      };
      const { code } = await followin(Data);
      if (code == 0) {
        this.getxinxi();
        this.getseenme();
      }
      this.tkL();
    },
    // 点击关注(我看过谁)
    async follow(item) {
      // console.log("点击当前用户关注状态", item);
      if (item.followStatus == 1) {
        this.zhuangtai = 0;
      } else {
        this.zhuangtai = 1;
      }
      let Data = {
        followMemberId: item.objId,
        opFlag: this.zhuangtai,
        tk: this.tk,
      };
      const { code } = await followin(Data);
      if (code == 0) {
        this.getxinxi();
        this.getseenme();
      }
      this.tkL();
    },

    // 谁看过我
    async getxinxi() {
      this.kanwo = [];
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        let b = data.user.id;
        this.idcard = data.user.id;
        this.isVipMember = data.user.isVipMember;
        // this.sex = data.user.sex;
        console.log("oo", data.user.sex);
        this.login();
        let Data = {
          objId: b,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          viewType: 1,
        };
        const a = await browsingPage(Data);
        if (a.code == 0) {
          // console.log(a.data.records, "谁看过我");
          //添加谁看过我
          a.data.records.map((item) => {
            // console.log("同性", this.sex, item.sex);
            // if (this.sex !== item.sex) {
            //  console.log('同性');
            // }else{
            item.updateTime = this.$moment(item.updateTime).format("l LTS");
            this.kanwo.push(
              Object.assign({}, item, {
                show: true,
                Img: ossL(item.browseAvatar),
                NewTime: new Date(item.updateTime).getTime(),
              })
            );
            // }
          });
          this.total = a.data.total;
          if (this.total <= 10) {
            this.fen = true;
          } else {
            this.fen = false;
          }
          if (this.kanwo.length == 0) {
            this.kong = true;
          } else {
            this.kong = false;
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.kanwo[i].browseEducation == this.educations[z].label) {
                this.kanwo[i].browseEducation = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.kanwo[i].browseBuyCarStatus == this.buyCarStatus[z].label
              ) {
                this.kanwo[i].browseBuyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (
                this.kanwo[i].browseHouseStatus == this.houseStatus[z].label
              ) {
                this.kanwo[i].browseHouseStatus = this.houseStatus[z].value;
              }
            }
          }
        }
      }
    },
    // 我看过谁
    async getseenme() {
      this.kanguo = [];
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        let a = data.user.id;
        let Data = {
          viewType: 0,
          memberId: a,
          pageNum: this.currentPage4,
          pageSize: this.pageSize,
        };
        const b = await browsingPage(Data);
        if (b.code == 0) {
          // console.log("我看过谁", b.data.records);
          b.data.records.map((item) => {
            item.updateTime = this.$moment(item.updateTime).format("l LTS");
            console.log("数据", item.updateTime);
            this.kanguo.push(
              Object.assign({}, item, {
                show: true,
                Img: ossL(item.browseAvatar),
                NewTime: new Date(item.updateTime).getTime(),
              })
            );
          });
          this.totalL = b.data.total;
          console.log("sshshhs", this.totalL);
          if (this.totalL <= 10) {
            this.ye = true;
          } else {
            this.ye = false;
          }

          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.kanguo[i].browseEducation == this.educations[z].label) {
                this.kanguo[i].browseEducation = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.kanguo[i].browseBuyCarStatus == this.buyCarStatus[z].label
              ) {
                this.kanguo[i].browseBuyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (
                this.kanguo[i].browseHouseStatus == this.houseStatus[z].label
              ) {
                this.kanguo[i].browseHouseStatus = this.houseStatus[z].value;
              }
            }
          }

          //数据为空
          if (this.kanguo.length == 0) {
            this.hong = true;
          } else {
            this.hong = false;
          }
        }
      }
    },
    async csh() {
      this.kanwo = [];
      this.kanguo = [];
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        let b = data.user.id;
        this.idcard = data.user.id;
        this.isVipMember = data.user.isVipMember;
        this.login();

        let Data = {
          objId: b,
          pageNum: this.currentPage4,
          pageSize: this.pageSize,
          viewType: 1,
        };
        //谁看过我
        const a = await browsingPage(Data);
        if (a.code == 0) {
          // console.log(a.data.records, "谁看过我");
          //添加谁看过我
          a.data.records.map((item) => {
            item.updateTime = this.$moment(item.updateTime).format("l LTS");
            this.kanwo.push(
              Object.assign({}, item, {
                show: true,
                Img: ossL(item.browseAvatar),
                NewTime: new Date(item.updateTime).getTime(),
              })
            );
          });
          this.total = a.data.total;
          // if (this.total <= 10) {
          //   this.fen = false;
          // } else {
          //   this.fen = true;
          // }

          if (this.totalL <= 10) {
            this.fen = true;
          } else {
            this.fen = false;
          }
          if (this.kanwo.length == 0) {
            this.kong = true;
          } else {
            this.kong = false;
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.kanwo[i].browseEducation == this.educations[z].label) {
                this.kanwo[i].browseEducation = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.kanwo[i].browseBuyCarStatus == this.buyCarStatus[z].label
              ) {
                this.kanwo[i].browseBuyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanwo.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (
                this.kanwo[i].browseHouseStatus == this.houseStatus[z].label
              ) {
                this.kanwo[i].browseHouseStatus = this.houseStatus[z].value;
              }
            }
          }
        }

        //------------我看过谁
        let c = data.user.id;
        let DataL = {
          viewType: 0,
          memberId: c,
          pageNum: this.currentPage4,
          pageSize: this.pageSize,
        };
        const d = await browsingPage(DataL);
        if (d.code == 0) {
          d.data.records.map((item) => {
            item.updateTime = this.$moment(item.updateTime).format("l LTS");
            this.kanguo.push(
              Object.assign({}, item, {
                show: true,
                Img: ossL(item.browseAvatar),
                NewTime: new Date(item.updateTime).getTime(),
              })
            );
          });
          this.totalL = d.data.total;
          if (this.totalL <= 10) {
            this.ye = true;
          } else {
            this.ye = false;
          }
          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.kanguo[i].browseEducation == this.educations[z].label) {
                this.kanguo[i].browseEducation = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.kanguo[i].browseBuyCarStatus == this.buyCarStatus[z].label
              ) {
                this.kanguo[i].browseBuyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.kanguo.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (
                this.kanguo[i].browseHouseStatus == this.houseStatus[z].label
              ) {
                this.kanguo[i].browseHouseStatus = this.houseStatus[z].value;
              }
            }
          }
          //数据为空
          if (this.kanguo.length == 0) {
            this.hong = true;
          } else {
            this.hong = false;
          }
        }
      }
    },
    //聊天
    chat(item) {
      // console.log('sss',item);
      if (this.isVipMember == 0) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else {
        let rel = this.$router.resolve({
          path: "/chat",
          query: {
            objectID: item,
          },
        });
        window.open(rel.href, "_blank");
      }
    },
    lie(num) {
      if (num == 1) {
        this.$refs.lie1.classList.add("active");
        this.$refs.lie2.classList.remove("active");
        this.lieB = true;
        this.lieA = false;
      } else {
        this.$refs.lie1.classList.remove("active");
        this.$refs.lie2.classList.add("active");
        this.lieB = false;
        this.lieA = true;
      }
    },
    open(item) {
      this.$confirm("你确定要删除这条访问记录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // type: "warning",
        center: true,
        lockScroll: false,
      })
        .then(async () => {
          let a = {
            browseViewId: item.id,
          };
          const { code } = await viewDel(a);
          if (code == 0) {
            this.getseenme();
            this.getxinxi();
            this.$message({
              type: "success",
              message: "删除成功!",
              offset: 100,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            offset: 100,
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.low {
  /deep/ .el-pagination {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }
}
//分页样式修改
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fd686eff !important;
  color: #fff;
  border: none !important;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #fff !important;
  min-width: 30px;
  border-radius: 2px;
  border: 1px solid #d8d8d8ff;
}

/deep/ .el-badge__content.is-fixed {
  right: 28px;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
//面包屑
.info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  height: 32px;
  border-bottom: 1px solid #f4f4f4ff;
  padding-left: 32px;
  p {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  p::before {
    content: "";
    width: 4px;
    height: 16px;
    background: #fd686e;
    position: absolute;
    top: 23px;
    left: 20px;
  }
}
.choice {
  display: flex;
  padding-top: 15px;
  padding-left: 24px;
  p {
    cursor: pointer;
    margin-right: 30px;
    height: 32px;
  }
  .active {
    font-size: 14px;
    font-weight: 400;
    color: #fd686e;
    border-bottom: 2px solid #fd686e;
  }
}
.follow {
  .messages-li {
    li:nth-child(1) {
      margin-top: 8px;
    }
    li {
      display: flex;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 14px;
      .touxi {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 106px;
        height: 106px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-left: 11px;
        img {
          width: 100px;
          height: 100px;
        }
      }

      .mess-box {
        // width: 555px;/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .mess-name {
          // margin-bottom: 12px;
          p {
            font-size: 16px;
          }
        }
        .qianmin {
          width: 408px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            color: #666;
          }
        }
        .cinum {
          color: #666;
        }
        .time {
          color: #adadad;
        }
      }
      .Btn-box {
        display: flex;
        margin-top: 68px;
        .Btnactive {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          // border: 1px solid #ecececff;
          background: #d8d8d8;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
        }
        .Btn {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #ffffffff;
          box-sizing: border-box;
          color: #fd686eff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          img {
            margin-right: 4px;
          }
          .b {
            display: none;
          }
        }
        .Btn:hover {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #fe747aff;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          .z {
            display: none;
          }
          .b {
            display: flex;
          }
          img {
            margin-right: 4px;
          }
        }
      }
    }
    li:hover {
      background-color: #fde9eaff;
      cursor: pointer;
    }
    .close {
      display: none;
      position: absolute;
      top: 16px;
      right: 20px;
      color: #adadadff;
      font-weight: bold;
    }
    li:hover .close {
      display: inline-block;
    }
  }
  .kong {
    // margin-top: 112px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 40px;
    }
    .btn-box {
      margin-top: 60px;
      width: 222px;
      height: 40px;
      background: #fd686e;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
